.pripolContainer {
    width: 62%;
    margin: auto;
    margin-top: 30px;
    transition: all ease 0.5s;
}

.privatlivspolitikHeader {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 1.5%;
    margin: 0;
    transition: font-size 0.2s ease;
}

.pripolSubHeader {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 22px;
    transition: font-size 0.2s ease;
}

.pripolSubSubHeader {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 22px;
    transition: font-size 0.2s ease;
}

.pripolText {
    font-size: 14px;
    margin-top: 4px;
}

.pripolLinks {
    display: inline-block;
}

.pripolUl {
    margin-bottom: 0;
}

.pripolLi {
    margin-bottom: 0;
}

.pripolComments {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
}

@media screen and (max-width: 550px) {
    .privatlivspolitikHeader {
        font-size: 26px;
    }
}

@media screen and (max-width: 400px) {
    .privatlivspolitikHeader {
        font-size: 22px;
    }
    .pripolSubHeader {
        font-size: 18px;
    }
    .pripolSubSubHeader {
        font-size: 14px;
    }
    .pripolText {
        font-size: 12px;
    }
}