.healthList {
    margin-top: 0;
}

.healthList li {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 15px;
    margin-left: -5px;
    margin-right: -10px;
}

.healthCitat {
    margin-top: 86px;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    margin-right: -38px;
    padding-left: 40px;
}

.healthLinksHeader {
    color: #7d1f33;
    font-size: 14px;
    font-weight: 600;
    padding-left: 40px;
}

.healthLink {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    padding-left: 40px;
    color: black;
}

.healthCarouselWrapper {
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    display: none;
}

.healthCarouselPrev,
.healthCarouselNext {
    width: 20px;
    position: absolute;
    top: 50%;
    margin-top: -23px;
    cursor: pointer;
    transition: 0.5s ease;
    padding: 5px 10px;
}

@media (hover: hover) {
    .healthCarouselPrev:hover div,
    .healthCarouselNext:hover div {
        background-color: #818181;
    }
}

.healthCarouselPrev {
    left: -40px;
}

.healthCarouselNext {
    right: -40px;
}

.healthPrevBar1 {
    transition: all 0.5s ease;
    margin: 6px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(-45deg);
}
.healthPrevBar2 {
    transition: all 0.5s ease;
    margin: 11px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(45deg);
}
.healthNextBar1 {
    transition: all 0.5s ease;
    margin: 6px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(45deg);
}
.healthNextBar2 {
    transition: all 0.5s ease;
    margin: 11px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(-45deg);
}


.healthImg1 {
    margin-right: 40px;
    width: 100%;
    height: 100%;
}

.healthImg2 {
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
    height: 100%;
}

.healthImg3 {
    margin-left: 40px;
    width: 100%;
    height: 100%;
}

.healthSliderImg {
    max-width: 100%;
}

.healthImgExplainerText {
    margin-top: 5px;
    font-size: 12px;
}

.healthImgSwiperTextMargins {
    margin-left: 2px;
    margin-right: 5px;
}

.healthCarouselAlignment {
    align-self: top;
    text-align: left;
}

@media screen and (max-width: 700px) {
    #healthTitle {
        font-size: 28px;
    }
}
@media screen and (max-width: 400px) {
    #healthTitle {
        font-size: 24px;
    }
}
@media screen and (max-width: 300px) {
    #healthTitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 1200px) {
    .healthList {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .healthList li {
        margin-left: -20px;
        margin-right: 0;
    }

    .healthCitat,
    .healthLinksHeader,
    .healthLink {
        margin-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0;
        margin-right: 0;
    }

    .willHide {
        display: none;
    }

    .healthCarouselWrapper {
        display: block;
    }

    .kontaktTekst {
        margin-top: 15px;
    }
}