#scrollToTopBtn {
    z-index: -1;
    opacity: 0;
    position: fixed;
    bottom: 30px;
    right: 80px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 10px;
    background-color: #555;
    transition: all 1s ease;
    color: white;
}

@media (hover: hover) {
    #scrollToTopBtn:hover {
        background-color: #333;
    }
}

/* move the button closer to the edge as the screen gets smaller */
@media screen and (max-width: 1350px) {
    #scrollToTopBtn {
        right: 50px;
    }
}
@media screen and (max-width: 650px) {
    #scrollToTopBtn {
        right: 25px;
    }
}
@media screen and (max-width: 450px) {
    #scrollToTopBtn {
        right: 10px;
    }
}