@font-face {
    font-family: 'DS-Dig';
    src: url('./DS-Digital.ttf');
}

.e404background {
    text-align: center;
    padding-bottom: 10%;
}

.e404text {
    font-size: 30px;
}

.backTo1030Link {
    font-family: 'DS-Dig';
    font-size: 20px;
}

.digitalText {
    font-family: 'DS-Dig';
    font-size: 20px;
}