.løsningerWrapper {
    padding-left: 17%;  /* 17% to match wdith of UndersideBanner, might need a change? */
    padding-right: 17%;
    text-align: center;
    overflow: hidden;
}

.løsningerHeader {
    font-size: 30px;
    font-weight: 600;
    color: #999999;
}

.loadingRow {
    transition: all 1s ease;
    height: 0;
    opacity: 0;
}

.spacingForLøsningerRows {
    margin-bottom: 0.5%;
}

.løsningerColumn {
    display: inline-block;
    width: 24%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    vertical-align: top;
}

@media screen and (max-width: 1200px) {
    .løsningerColumn {
        width: 49%;
    }
}

.løsningerBilleder {
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
}

.løsningerHashtags {
    font-size: 12px;
    letter-spacing: 0px;
    font-weight: 400;
    text-align: left;
    margin-top: 0;
    line-height: 1.1;
    transition: font-size 0.2s ease;
}

@media screen and (max-width: 1530px) {
    .løsningerHashtags {
        font-size: 10px;
    }
}
@media screen and (max-width: 820px) {
    .løsningerHashtags {
        font-size: 9px;
    }
}

#showMoreArrow,
#showLessArrow {
    display: inline-block;
    position: absolute;
    width: 1.5vw;
    height: 1.5vw;
    cursor: pointer;
    transition: all 0.5s ease;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    border-width: 0.7vw;
    box-shadow: -2px -2px black inset;
    transform: rotate(45deg);
}

@media (hover: hover) {
    #showMoreArrow:hover,
    #showLessArrow:hover {
        box-shadow: -2px -2px #818181 inset;
    }
}

#showMoreArrow {
    left: 48.5%;
}

#showLessArrow {
    display: none;
    right: 34%;
    transform: rotate(-135deg);
    margin: 1.2%;
}

@media screen and (max-width: 850px) {
    #showMoreArrow,
    #showLessArrow {
        width: 2vw;
        height: 2vw;
        border-width: 2vw;
    }

    #showMoreArrow {
        left: 46.9%;
    }
}
@media screen and (max-width: 550px) {
    #showMoreArrow,
    #showLessArrow {
        width: 2.5vw;
        height: 2.5vw;
        border-width: 4vw;
    }

    #showMoreArrow {
        left: 44.8%;
    }

    #showLessArrow {
        margin: 1.5%;
    }
}

.løsningerSlideshowBackground {
    z-index: -10;
    display: block;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .5s ease;
}

.løsningerCloseSlideshowBtn {
    cursor: pointer;
    display: inline-block;
    position: fixed;
    top: 22px;
    right: 20px;
    z-index: 150;
    padding-top: 20px;
    padding-bottom: 20px;   /* large padding in order to make it easier to click the icon (especially on phones) */
    padding-left: 20px;
    padding-right: 20px;
    transition: all 0.5s ease;  /* makes movements more subtle when resizing window, instead of instantly changing place */
}

.løsningerCloseSlideshowBtn:hover div {
    background-color: #818181;
}

.løsningerSlideCloseBar1,
.løsningerSlideCloseBar2,
.løsningerSlideCloseBar3 {
    width: 35px;
    height: 2px;
    background-color: black;    /* #5C5B5A hvis der vil bruges samme grå farve som i 10:30 logoet */
    transition: 0.5s;
}

.løsningerSlideCloseBar1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 7px);
    transform: rotate(-45deg) translate(-8px, 7px);
    margin: 6px 0;
}

.løsningerSlideCloseBar2 {
    opacity: 0;
    margin: 6px 0;
}

.løsningerSlideCloseBar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
    margin: 12px 0;
}

.løsningerSlideshowContainer {
    position: absolute;
    /* this element is smaller than the parent so that it is possible to click the parent in order to close the slideshow */
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    top: 15vh;
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease;
}

.løsningerPrevSlide,
.løsningerNextSlide {
    width: 40px;
    position: absolute;
    top: 29vh;
    z-index: 150;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 20px 20px;
    padding-top: 40px;
}

@media (hover: hover) {
    .løsningerPrevSlide:hover div,
    .løsningerNextSlide:hover div {
        background-color: #818181;
    }
}

.løsningerPrevSlide {
    left: 0;
}

.løsningerNextSlide {
    right: 0;
}

.løsningerPrevBar1 {
    transition: all 0.5s ease;
    margin: 6px 0;
    height: 2px;
    background-color: black;
    transform: rotate(-45deg);
}

.løsningerPrevBar2 {
    transition: all 0.5s ease;
    margin: 26px 0;
    height: 2px;
    background-color: black;
    transform: rotate(45deg);
}

.løsningerNextBar1 {
    transition: all 0.5s ease;
    margin: 6px 0;
    height: 2px;
    background-color: black;
    transform: rotate(45deg);
}

.løsningerNextBar2 {
    transition: all 0.5s ease;
    margin: 26px 0;
    height: 2px;
    background-color: black;
    transform: rotate(-45deg);
}

.løsningerLightboxSwiperSlideAlignment {
    align-self: center;
    text-align: center;
}

.løsningerLightboxSlides {
    max-height: 70vh;
    pointer-events: none;
    transition: all 0.3s ease;
}

.løsningerLightboxTextarea {
    display: inline-block;
    vertical-align: top;
    height: 70vh;
    width: 35vh;
    background-color: white;
    padding: 0 1vh;
    transition: all 0.3s ease;
}

.løsningerLightboxText {
    text-align: left;
    font-size: 14px;
    margin-top: 33vh;
    transform: translate(0, -50%);  /* gradually moves the text up as the amount of text grows, so long paragraphs wont just end up being at the bottom */
    transition: all 0.3s ease;
}

@media screen and (max-width: 1500px) {
    .løsningerSlideshowContainer {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
}
@media screen and (max-width: 1300px) {
    .løsningerSlideshowContainer {
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
}
@media screen and (max-width: 1230px) {
    .løsningerLightboxSlides {
        max-width: 60vw;
        max-height: 60vh;
    }
    .løsningerLightboxTextarea {
        height: 60vh;
        width: 30vh;
    }
    .løsningerLightboxText {
        margin-top: 28vh;
    }
    .løsningerSlideshowContainer {
        top: 20vh;
    }
    .løsningerPrevSlide,
    .løsningerNextSlide {
        top: 22vh;
    }
}
@media screen and (max-width: 1075px) {
    .løsningerLightboxWrapper {
        margin: auto;
        width: 60vh;
        max-width: 60vw;
        transition: all 0.3s ease;
    }
    .løsningerSlideshowContainer {
        top: 10vh;
    }
    .løsningerPrevSlide,
    .løsningerNextSlide {
        top: 28vh;
    }
    .løsningerLightboxTextarea {
        display: block;
        height: fit-content;
        margin-top: -5px;
        padding: 0;
        padding-top: 1vh;
        padding-bottom: 1vh;
        width: 100%;
    }
    .løsningerLightboxText {
        margin: 0;
        transform: translate(0,0);
        margin-left: 1vw;
        margin-right: 1vw;
    }
}
@media screen and (max-width: 775px) {
    .løsningerSlideshowContainer {
        top: 16vh
    }
    .løsningerPrevSlide {
        left: -10px;
        top: 18vh;
    }
    .løsningerNextSlide {
        right: -10px;
        top: 18vh;
    }
}
@media screen and (max-width: 650px) {
    .løsningerSlideshowContainer {
        top: 21vh
    }
    .løsningerPrevSlide,
    .løsningerNextSlide {
        top: 15vh;
    }
}
@media screen and (max-width: 550px) {
    .løsningerSlideshowContainer {
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
        top: 23vh;
    }
    .løsningerPrevSlide,
    .løsningerNextSlide {
        top: 12vh;
    }
}
@media screen and (max-width: 450px) {
    .løsningerSlideshowContainer {
        top: 30vh;
    }
    .løsningerPrevSlide,
    .løsningerNextSlide {
        top: 7vh;
    }
}
@media screen and (max-width: 350px) {
    .løsningerSlideshowContainer {
        top: 35vh;
    }
    .løsningerPrevSlide,
    .løsningerNextSlide {
        top: 3vh;
    }
}
@media screen and (max-height: 700px) and (min-width: 700px) {
    .løsningerSlideshowContainer {
        top: 12vh;
    }
    .løsningerPrevSlide,
    .løsningerNextSlide {
        top: 22vh;
    }
}