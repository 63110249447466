.flyoutContainer {
    display: inline-block;
    position: fixed;
    top: 22px;
    right: 20px;
    cursor: pointer;
    z-index: 110;
    padding-top: 20px;
    padding-bottom: 20px;   /* large paddi in order to make it easier to click the menu (especially on phones) */
    padding-left: 20px;
    padding-right: 20px;
    transition: all 0.5s ease;  /* makes movements more subtle when resizing window, instead of instantly changing place */
}

/* move the menu as the screen gets smaller */
@media screen and (max-width: 1670px) {
    .flyoutContainer {
        top: 20px;
        right: 10px;
    }
}
@media screen and (max-width: 1350px) {
    .flyoutContainer {
        top: 22px;
        right: 0px;
    }
}
@media screen and (max-width: 1080px) {
    .flyoutContainer {
        top: 12px;
    }
}
@media screen and (max-width: 930px) {
    .flyoutContainer {
        top: -10px;
    }
}

@media (hover: hover) {
    .flyoutContainer:hover div {
        background-color: #818181;
    }
}

.menuIconBar1,
.menuIconBar2,
.menuIconBar3 {
    width: 35px;
    height: 2px;
    background-color: black;    /* #5C5B5A hvis der vil bruges samme grå farve som i 10:30 logoet */
    margin: 10px 0;
    transition: 0.5s;
}

.transformToX .menuIconBar1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 7px);
    transform: rotate(-45deg) translate(-8px, 7px);
    margin: 6px 0;
}
.transformToX .menuIconBar2 {
    opacity: 0;
    margin: 6px 0;
}
.transformToX .menuIconBar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
    margin: 12px 0;
}

.navOverlay {
    height: 0;
    width: 0;
    position: fixed;
    z-index: 100;
    right: 0;
    top: 0;
    background-color: white;
    overflow-x: hidden;
    overflow-y: hidden;     /* disable scroll */
    transition: 0.3s;
}

.menuHoverImg {
    top: 0;
    width: 100%;
    transition: all 0.5s ease;
    position: absolute;
    opacity: 0;
}

@media screen and (max-width: 1500px) {
    .menuHoverImg {
        top: 10%;
    }
}

@media screen and (max-width: 1200px) {
    .menuHoverImg {
        top: 17%;
    }
}

@media screen and (max-width: 1000px) {
    .menuHoverImg {
        top: 22%;
    }
}

@media screen and (max-width: 800px) {
    .menuHoverImg {
        top: 25%;
    }
}

.navOptions {
    position: relative;
    top: 25vh;
    width: 100%;
    text-align: center;
}

.navOptions a {
    font-size: 30px;
    padding: 8px;
    display: block;
    text-decoration: none;  /* removes underline (cause technically they are links) */
    color: black;
    transition: 0.2s;
    margin-bottom: 0.5%;
    margin-left: 30%;
    margin-right: 30%;
}

@media screen and (max-width: 850px) {
    .navOptions a {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media screen and (max-width: 500px) {
    .navOptions a {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.navOptions a:hover,
.navOptions a:focus {
    color: #818181;
}

/* make stuff fit on smaller screens */
@media screen and (max-height: 450px) {
    .navOverlay a {
        font-size: 20px;
    }
}