.medarbWrapper {
    padding-left: 29.5%;
    padding-right: 29.5%;
    margin-bottom: 4%;
}

.medarbNavne {
    color: #848484;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
    margin-top: 5px;
    transition: font-size 0.2s ease;
}

.beskrivelse {
    font-size: 14px;
    margin-bottom: 0;
    transition: all 0.2s ease;
}

.mail {
    color: #ee8939;
    font-size: 14px;
    margin-top: 0;
    transition: all 0.2s ease;
}

.medarbColumns {
    display: inline-block;
    width: 31.33%;
    text-align: center;
    margin-top: 4%;
    padding-left: 1%;
    padding-right: 1%;
    vertical-align: top;
}

@media screen and (max-width: 1200px) {
    .medarbColumns {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .medarbNavne {
        font-size: 16px;
    }

    .beskrivelse {
        font-size: 12px;
    }

    .mail {
        font-size: 12px;
    }
}