.coprodList {
    margin-top: 86px;
    transition: all 0.5s ease;    /* makes movements more subtle when resizing window, instead of instantly changing place */
}

.coprodList li {
    font-size: 14px;
    margin-bottom: 18px;
    padding-right: 10px;
    margin-left: -10px;
    line-height: 1.4;
}

.coprodImg {
    max-width: 100%;
    max-height: 100%;
    margin-top: 15px;
    transition: all 0.5s ease;    /* makes movements more subtle when resizing window, instead of instantly changing place */
}

.coprodImgJustify {
    text-align: right;
}

@media screen and (max-width: 1790px) {
    #coprodTitle {
        font-size: 26px;
    }

    .coprodList {
        margin-top: 78px;
    }
}

@media screen and (max-width: 1460px) {
    #coprodTitle {
        font-size: 24px;
    }

    .coprodList {
        margin-top: 76px;
    }
}

@media screen and (max-width: 1460px) {
    #coprodTitle {
        font-size: 24px;
    }

    .coprodList, .coprodImg {
        margin-top: 76px;
    }
}

@media screen and (max-width: 1350px) {
    #coprodTitle {
        font-size: 20px;
    }

    .coprodList {
        margin-top: 72px;
    }
}

@media screen and (max-width: 1200px) {
    .coprodList li {
        padding-right: 0;
        margin-left: -20px;
    }

    .coprodList, .coprodImg {
        margin-top: 10px;
    }

    .coprodImgJustify {
        text-align: center;
    }

    #coprodTitle {
        font-size: 32px;
    }
}

@media screen and (max-width: 700px) {
    #coprodTitle {
        font-size: 26px;
    }
}

@media screen and (max-width: 550px) {
    #coprodTitle {
        font-size: 22px;
    }
}

@media screen and (max-width: 400px) {
    #coprodTitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 350px) {
    #coprodTitle {
        font-size: 16px;
    }
}