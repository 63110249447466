.slideshowContainer {
    width: 80%;
    margin: auto;
    position: relative;
    overflow: hidden;
    margin-bottom: 3px;
}

@media screen and (max-width: 930px) {
    .slideshowContainer {
        width: 100%;
    }
}

/* hide images by default */
.headerSlide {
    display: none;
    max-width: 100%;
    margin-bottom: 6px;
    overflow: hidden;
    text-align: center;
}

.headerSlideBillede {
    max-height: 100%;
    max-width: 100%;
    margin-bottom: 0;
}

.fade {
    animation: fade 1s;
    -webkit-animation: fade 1s;
    -moz-animation: fade 1s;
    -o-animation: fade 1s;
}

@keyframes fade {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fade {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fade {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes fade {
    from { opacity: 0.5; }
    to   { opacity: 1; }
}

.slowFade {
    animation: slowFade 3s;
    -webkit-animation: slowFade 3s;
    -moz-animation: slowFade 3s;
    -o-animation: slowFade 3s;
}

@keyframes slowFade {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes slowFade {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slowFade {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes slowFade {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.headerSliderLinks {
    text-decoration: none;
    color: black;
}

.headerSliderCaption {
    font-size: 60px;
    font-weight: 500;
    padding: 8px 18px;
    position: absolute;
    bottom: 35%;
    text-align: center;
    background-color: rgba(232, 232, 232, 0.6);
    /*transform: translate(-50%, 0);  can be used to move the div left by half its own length */
}

#healthSliderCaption {
    right: 0;
    padding-right: 33%;
    bottom: 20%;
}

#adfærdSliderCaption {
    left: 0;
    padding-left: 8%;
    bottom: 60%;
}

#natureSliderCaption {
    left: 0;
    padding-left: 25%;
    bottom: 67%;
}

#d2dSliderCaption {
    left: 0;
    padding-left: 24%;
    bottom: 40%;
}

#coprodSliderCaption {
    right: 0;
    padding-right: 10%;
    bottom: 65%;
}

@media screen and (max-width: 1200px) {
    .headerSliderCaption {
        font-size: 30px;
    }

    #adfærdSliderCaption {
        font-size: 34px;
    }

    #natureSliderCaption {
        font-size: 34px;
    }
}
@media screen and (max-width: 700px) {
    .headerSliderCaption {
        font-size: 20px;
    }

    #healthSliderCaption {
        bottom: 30%;
    }

    #adfærdSliderCaption {
        font-size: 20px;
        bottom: 50%;
    }

    #natureSliderCaption {
        bottom: 60%;
        font-size: 20px;
    }
}

.paginationDot {
    cursor: pointer;
    height: 7px;
    width: 7px;
    margin: 0 2px;
    margin-top: 0;
    margin-bottom: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.activeDot {
    background-color: #717171;
}

@media (hover: hover) {
    .paginationDot:hover {
        background-color: #717171;
    }
}