.day2dayList li {
  margin-bottom: 15px;
  margin-left: -10px;
  font-size: 14px;
  line-height: 1.4;
  padding-right: 5px;
}

.d2dListHeader {
  transition: all 0.3s ease;
}

@media screen and (max-width: 1200px) {
  .day2dayList li {
    margin-left: -20px;
    padding-right: 0;
  }
}

/*  IF A SPECIAL CONTAINER IS NEEDED FOR THE 4 BOTTOM COLUMNS OF D2D
.d2dColumnContainer {
  width: 62%;
  margin: auto;
  margin-top: 30px;
}
*/

.d2dColumn {
  vertical-align: top;
  width: 23%;
  margin-left: 1%;
  margin-right: 1%;
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s ease;
}

@media screen and (max-width: 1780px) {
  #d2dTitle {
    font-size: 28px;
  }

  #d2dListHeader {
    margin-top: 82px;
  }
}

@media screen and (max-width: 1556px) {
  #d2dListHeader {
    margin-top: 114px;
  }
}

@media screen and (max-width: 1400px) {
  #d2dTitle {
    font-size: 26px;
  }

  #d2dListHeader {
    margin-top: 110px;
  }
}

@media screen and (max-width: 1500px) {
  .d2dColumn {
    width: 48%;
  }
}

@media screen and (max-width: 1200px) {
  .d2dColumn {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  #d2dTitle {
    font-size: 32px;
  }

  #d2dListHeader {
    margin-top: 10px;
  }
}

@media screen and (max-width: 900px) {
  #d2dTitle {
    font-size: 28px;
  }
}

@media screen and (max-width: 650px) {
  #d2dTitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 500px) {
  #d2dTitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 370px) {
  #d2dTitle {
    font-size: 16px;
  }
}

.d2dColumn:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.d2dFeatureTitle {
  padding-top: 10%;
  padding-bottom: 10%;
}

.d2dGallerySlideshowBackground {
  z-index: -10;
  display: block;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all .5s ease;
}

.d2dCloseSlideshowBtn {
  cursor: pointer;
  display: inline-block;
  position: fixed;
  top: 22px;
  right: 20px;
  z-index: 150;
  padding-top: 20px;
  padding-bottom: 20px;   /* large padding in order to make it easier to click the icon (especially on phones) */
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.5s ease;  /* makes movements more subtle when resizing window, instead of instantly changing place */
}

.d2dCloseSlideshowBtn:hover div {
  background-color: #818181;
}

.d2dSlideCloseBar1,
.d2dSlideCloseBar2,
.d2dSlideCloseBar3 {
  width: 35px;
  height: 2px;
  background-color: black;  /* #5C5B5A hvis der vil bruges samme grå farve som i 10:30 logoet */
  transition: 0.5s;
}

.d2dSlideCloseBar1 {
  -webkit-transform: rotate(-45deg) translate(-8px, 7px);
  transform: rotate(-45deg) translate(-8px, 7px);
  margin: 6px 0;
}

.d2dSlideCloseBar2 {
  opacity: 0;
  margin: 6px 0;
}

.d2dSlideCloseBar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
  margin: 12px 0;
}

.d2dSlideshowContainer {
  position: absolute;
  width: 80%;
  padding: 0;
  top: 23%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  overflow: hidden;
}

.d2dPrevSlide, .d2dNextSlide {
  width: 40px;
  position: absolute;
  top: 33%;
  z-index: 150;
  cursor: pointer;
  transition: all 0.5s ease;
  padding: 20px 20px;
  padding-top: 40px;
}

.d2dPrevSlide:hover div, .d2dNextSlide:hover div {
  background-color: #818181;
}

.d2dPrevSlide {
  left: 0;
}

.d2dNextSlide {
  right: 0;
}

@media screen and (max-width: 775px) {
  .d2dPrevSlide {
    left: -25px;
  }
  .d2dNextSlide {
    right: -25px;
  }
}
@media screen and (max-width: 500px) {
  .d2dPrevSlide, .d2dNextSlide {
    top: 25%;
  }
}

.d2dPrevBar1 {
  transition: all 0.5s ease;
  margin: 6px 0;
  height: 2px;
  background-color: black;
  transform: rotate(-45deg);
}
.d2dPrevBar2 {
  transition: all 0.5s ease;
  margin: 26px 0;
  height: 2px;
  background-color: black;
  transform: rotate(45deg);
}
.d2dNextBar1 {
  transition: all 0.5s ease;
  margin: 6px 0;
  height: 2px;
  background-color: black;
  transform: rotate(45deg);
}
.d2dNextBar2 {
  transition: all 0.5s ease;
  margin: 26px 0;
  height: 2px;
  background-color: black;
  transform: rotate(-45deg);
}

.d2dSwiperSlideAlignment {
  align-self: center;
  text-align: center;
}

.d2dSlidesLogo {
  max-width: 80%;
  border: 10px solid white;
  pointer-events: none;
}

.d2dSlidesIllustrationer {
  pointer-events: none;
}

.d2dSlidesWeb {
  
  pointer-events: none;
}

.d2dSlidesPrinted {
  pointer-events: none;
}