.natureList li {
  margin-bottom: 20px;
  margin-left: -10px;
  font-size: 14px;
  line-height: 1.4;
  padding-right: 5px;
}

.natureVideo {
  padding-left: 2px;
}

.nopadding {
  padding-left: 1px;
}

.natureFixTop {
  transition: all 0.3s ease;
}

@media screen and (max-width: 1966px) {
  .natureFixTop {
    margin-top: 126px;
  }
}

@media screen and (max-width: 1400px) {
  #natureTitle {
    font-size: 26px;
  }
  .natureFixTop {
    margin-top: 110px;
  }
}

@media screen and (max-width: 1200px) {
  #natureTitle {
    font-size: 32px;
  }

  .natureList li {
    margin-left: -20px;
  }

  .natureFixTop {
    margin-top: 10px;
  }

  .natureVideo {
    padding-left: 0;
  }

  .nopadding {
    padding-left: 0;
  }
}

@media screen and (max-width: 900px) {
  #natureTitle {
    font-size: 28px;
  }
}

@media screen and (max-width: 650px) {
  #natureTitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 550px) {
  #natureTitle {
    font-size: 20px;
  }
}