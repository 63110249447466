#cookieBackground {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cookieBox {
    z-index: 201;
    background-color: white;
    width: 40%;
    padding: 30px 40px 40px 40px;
    border-radius: 14px;
    max-height: 80%;
    transition: all 0.5s ease;
}

@media screen and (max-width: 1200px) {
    .cookiesUnfolded {
        width: 90%;
    }
}

.cookieHeader {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.cookieText {
    font-size: 14px;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 8px;
    transition: font-size 0.2s ease;
}

@media screen and (max-width: 515px) {
    .cookieText {
        font-size: 13px;
    }
}
@media screen and (max-width: 320px) {
    .cookieText {
        font-size: 12px;
    }
}

/* when the screen height is low, we have to hide some text in order to make sure the OK button stays visible without scrolling */
@media screen and (max-height: 650px) {
    .hideOnSmallHeight {
        display: none;
    }
}

.privacyPolicyLink {
    font-size: inherit;
    text-decoration: underline;
    color: blue;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font: inherit;
}

#pripolFoldOutBtn {
    display: none;
    margin-top: 0;
}

#pripolUnfolder {
    width: 100%;
    max-height: 0;
    overflow-y: scroll;
    transition: all 0.7s ease;
    margin-bottom: 0.5vh;
}

.cookieButton {
    margin-left: 35%;
    cursor: pointer;
    background-color: #141414;
    color: #f4f4f4;
    width: 30%;
    padding: 12px 0;
    appearance: none;
    margin-top: 0.2vh;
    margin-bottom: 1.5vh;
}

.switchBox {
    margin-top: 0;
    background-color: #141414;
    margin-left: -40px;
    margin-right: -40px;
    margin-bottom: -50px;
    border-radius: 0px 0px 14px 14px;
    padding: 30px 40px;
}

.cookieSwitchColumn {
    display: inline-block;
    width: 25%;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .cookieSwitchColumn {
        width: 100%;
    }

    .cookieSwitchHide {
        display: none;
    }
}

.cookieSwitchText {
    color: #f4f4f4;
    font-size: 14px;
    margin-top: -0.5vh;
}

.cookieSwitch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.cookieSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switchSlider {
    position: absolute;
    cursor: not-allowed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switchSlider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .switchSlider {
    background-color: #D2CE27;
}

input:focus + .switchSlider {
    box-shadow: 0 0 1px #D2CE27;
}

input:checked + .switchSlider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}