.footerExtension {
    height: 8vh;
    background-color: #dedede;
}

.footerBaggrund {
    background-image: url('footer_skyline_tall.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 10%;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 2vh;
}

.footerColumn {
    vertical-align: top;
    display: inline-block;
    width: 20%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    text-align: left;
}

@media screen and (max-width: 1200px) {
    .footerColumn {
        width: 100%;
    }
}

.footerLink {
    color: white;
}

.footerMenu {
    color: white;
    display: block;
    text-decoration: none;
    margin-bottom: 4px;
    letter-spacing: 0.2px;
}

.footerHeader {
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-top: 4vh;
    margin-bottom: 2.5vh;
    letter-spacing: 2px;
}

.footerText {
    color: white;
    margin: 0;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.2px;
}

.footerCitatText {
    font-size: 14px;
    color: white;
    font-style: italic;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.2px;
    margin-top: 4vh;
}

.footerCopyright {
    color: white;
    font-weight: 500;
    font-size: 11px;
    margin-top: 6%;
    letter-spacing: 0.2px;
}

#copyrightYear {
    display: inline-block;
}

/** FOR SOCIAL MEDIA BUTTONS */
.fa {
    font-size: 30px;
    width: 10px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-instagram {
    color: white;
}

/*
.fa-linkedin {
    color: white;
    padding-left: 5%;
}
*/