.ooHeading {
    font-size: 32px;
    line-height: 1.5;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 2%;
    padding-bottom: 1.5%;
    margin: 0;
    transition: font-size 0.2s ease;
}

.ooColumns {
    column-count: 2;
    column-gap: 4%;
    padding-left: 30%;
    padding-right: 30%;
    padding-bottom: 2vh;
    transition: all 0.5s ease;
}

@media screen and (max-width: 1200px) {
    .ooColumns {
        column-count: 1;
    }
}

@media screen and (max-width: 930px) {
    .ooHeading {
        font-size: 24px;
    }

    .ooColumns {
        padding-left: 20%;
        padding-right: 20%;
    }
}

@media screen and (max-width: 550px) {
    .ooHeading {
        font-size: 20px;
    }

    .ooColumns {
        padding-left: 15%;
        padding-right: 15%;
    }
}

.ooText {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
}

.ooBgcolor {
    margin: 0;
    background-color: #fafafa;
}