.kundeHeader {
    font-size: 30px;
    font-weight: 600;
    color: #999999;
    letter-spacing: 0;
    line-height: 1.5;
    text-align: center;
    padding-left: 30%;
    padding-right: 30%;
    margin-bottom: 1.5vh;
    margin-top: 10vh;
    transition: all 0.5s ease;
}

.transitionFontSize {
    transition: font-size 0.2s ease;
}

.carouselWrapper {
    position: relative;
    margin-left: 30%;
    margin-right: 30%;
    margin-bottom: 30px;
}

.kundeCarouselPrev,
.kundeCarouselNext {
    width: 20px;
    position: absolute;
    top: 50%;
    margin-top: -23px;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 5px 10px;
}

@media (hover: hover) {
    .kundeCarouselPrev:hover div,
    .kundeCarouselNext:hover div {
        background-color: #818181;
    }
}

.kundeCarouselPrev {
    left: -45px;
}

.kundeCarouselNext {
    right: -45px;
}

.kundePrevBar1 {
    transition: all 0.5s ease;
    margin: 6px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(-45deg);
}
.kundePrevBar2 {
    transition: all 0.5s ease;
    margin: 11px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(45deg);
}
.kundeNextBar1 {
    transition: all 0.5s ease;
    margin: 6px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(45deg);
}
.kundeNextBar2 {
    transition: all 0.5s ease;
    margin: 11px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(-45deg);
}

.kundeCarouselAligning {
    text-align: center;
    align-self: center;
}

.swiperKundeImages {
    max-height: 90px;
    max-width: 100%;
}

.yderligereErfaringBaggrund {
    background-color: #dedede;
    padding-top: 1px;
    padding-bottom: 1px;
}

.yderligereErfaringHeader {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5;
    text-align: center;
    padding-left: 30%;
    padding-right: 30%;
}

.yderligereErfaring {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding-left: 30%;
    padding-right: 30%;
}

@media screen and (max-width: 1200px) {
    .kundeCarouselNext,
    .kundeCarouselPrev {
        margin-top: -17px;
    }
}

@media screen and (max-width: 1050px) {
    .kundeHeader {
        font-size: 26px;
    }

    .yderligereErfaringHeader {
        font-size: 16px;
    }

    .yderligereErfaring {
        font-size: 12px;
    }
}

@media screen and (max-width: 860px) {
    .kundeHeader {
        font-size: 22px;
    }

    .yderligereErfaringHeader {
        font-size: 14px;
    }
}

@media screen and (max-height: 600px) {
    .kundeHeader {
        margin-top: 20vh;
    }
}