#changeCookieSettings {
    position: fixed;
    bottom: 5px;
    left: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: all 0.5s ease;
    z-index: 100;
}

#changeCookieSettings:hover {
    width: 50px;
    height: 50px;
}

#changeCookieIcon {
    max-width: 100%;
}