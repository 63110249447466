/* css in here is site-wide */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  word-wrap: break-word;
}

p {
  font-size: 14px;
  font-weight: 400;
}

.noPointerEvents {
  pointer-events: none; /* disables the ability to right click images to save them (among other things, like clicking and dragging) */
}

.overskrift {
  font-family: 'Montserrat', sans-serif;
}

.kontaktTekst {
  font-family: 'Montserrat', sans-serif;
  margin-left: 1%;
  margin-right: 1%;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  line-height: 1.6;
  margin-top: 35px;
  margin-bottom: 50px;
  transition: all 0.2s ease;    /* makes movements more subtle when resizing window, instead of instantly changing fontsize */
}

@media screen and (max-width: 1000px) {
  .kontaktTekst {
    font-size: 18px;
  }
}
@media screen and (max-width: 650px) {
  .kontaktTekst {
    font-size: 14px;
  }
}

.undersideHeaderImage {
  pointer-events: none; /* disables the ability to right click images to save them (among other things, like clicking and dragging images) */
  max-width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5px;
  animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -o-animation: fadein 2s;
}
@media screen and (max-width: 930px) {
  .undersideHeaderImage {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.undersideColumnContainer {
  width: 62%;
  margin: auto;
  margin-top: 30px;
}

.undersideColumn {
  vertical-align: top;
  width: 31.333333%;
  margin-left: 1%;
  margin-right: 1%;
  display: inline-block;
}

.undersideTagline {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #7d1f33;
}

.undersideTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;
  transition: font-size 0.2s ease;
}

.undersideText {
  font-size: 14px;
  line-height: 1.5;
  padding-right: 5px;
}

.undersideSubHeaders {
  font-family: 'Montserrat', sans-serif;
  color: #7d1f33;
  font-size: 14px;
  font-weight: 600;
  margin-top: 86px;
  padding-left: 10px;
}

@media screen and (max-width: 1200px) {
  .undersideColumn {
    width: 100%;
  }

  .undersideText {
    padding-right: 0;
  }

  .undersideSubHeaders {
    margin-top: 10px;
    padding-left: 0;
  }
}