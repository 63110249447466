.undersideBannerBaggrund {
    background-color: #ee8939;
    padding-left: 17%;
    padding-right: 17%;     /* 17% chosen as that seems closest when comparing to the mockup */
    margin-bottom: 20px;
    display: flex;      /* makes it possible for the hover effect on individual columns to fill the div height */
}

.undersideBannerColumn {
    display: inline-block;
    width: 18.8%;
    padding-left: 0.8%;
    padding-right: 0.4%;    /* both paddings + width should give 20%, because there are 5 columns, and 100/5 = 20 */
    vertical-align: top;
    text-decoration: none;
    transition: background-color .5s ease;
}

.undersideBannerColumn:hover {
    background-color: #e8a166;
    cursor: pointer;
}

.undersideBannerHeader {
    color: white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.1;
    margin-top: 25px;
    margin-bottom: 0;
}

.undersideBannerText {
    color: white;
    font-size: 12px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 25px;
}

/* note: 1560px width is when ADFÆRDSDESIGN goes on a new line, and messes up the alignment of the titles a bit */
@media screen and (max-width: 1200px) {
    .undersideBannerBaggrund {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
    
    .undersideBannerColumn {
        width: 66%;
        padding-left: 17%;
        padding-right: 17%;
    }
}