.adfaerdList {
    margin-top: 86px;
    margin-bottom: 30px;
}

.adfaerdList li {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 10px;
    margin-left: -5px;
    margin-right: -10px;
    padding-right: 0;
}

.adfærdImg1 {
    margin-right: 40px;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
}

.adfærdImg2 {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 100%;
    max-height: 100%;
}

.adfærdImg3 {
    margin-left: 40px;
    max-width: 100%;
    max-height: 100%;
}

.adfærdSliderImg {
    max-width: 100%;
}

.adfærdCarouselWrapper {
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    display: none;
}

.adfærdCarouselPrev,
.adfærdCarouselNext {
    width: 20px;
    position: absolute;
    top: 50%;
    margin-top: -23px;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 5px 10px;
}

@media (hover: hover) {
    .adfærdCarouselPrev:hover div,
    .adfærdCarouselNext:hover div {
        background-color: #818181;
    }
}

.adfærdCarouselPrev {
    left: -40px;
}

.adfærdCarouselNext {
    right: -40px;
}

.adfærdPrevBar1 {
    transition: all 0.5s ease;
    margin: 6px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(-45deg);
}
.adfærdPrevBar2 {
    transition: all 0.5s ease;
    margin: 11px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(45deg);
}
.adfærdNextBar1 {
    transition: all 0.5s ease;
    margin: 6px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(45deg);
}
.adfærdNextBar2 {
    transition: all 0.5s ease;
    margin: 11px 0;
    height: 2px;
    background-color: #bcbcbc;
    transform: rotate(-45deg);
}

@media screen and (max-width: 1320px) {
    #adfærdTitle {
        font-size: 28px;
    }
}

@media screen and (max-width: 1200px) {
    #adfærdTitle {
        font-size: 32px;
    }

    .adfaerdList {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .adfaerdList li {
        margin-left: -20px;
        margin-right: 0;
    }

    .willHide {
        display: none;
    }

    .adfærdCarouselWrapper {
        display: block;
    }

    .kontaktTekst {
        margin-top: 15px;
    }
}

@media screen and (max-width: 700px) {
    #adfærdTitle {
        font-size: 28px;
    }
}

@media screen and (max-width: 500px) {
    #adfærdTitle {
        font-size: 24px;
    }
}

@media screen and (max-width: 350px) {
    #adfærdTitle {
        font-size: 20px;
    }
}