.topLogo {
    position: fixed;
    cursor: pointer;
    z-index: 10;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 22px;
    width: 150px;
    transition: all 0.5s ease;    /* makes movements more subtle when resizing window, instead of instantly changing place */
}

@media screen and (max-width: 1670px) {
    .topLogo {
        top: 26px;
        width: 120px;
    }
}

@media screen and (max-width: 1350px) {
    .topLogo {
        top: 36px;
        width: 90px;
    }
}

@media screen and (max-width: 1080px) {
    .topLogo {
        top: 30px;
        width: 70px;
    }
}

@media screen and (max-width: 930px) {
    .topLogo {
        position: relative;
        top: 0;
        width: 100px;
    }
}